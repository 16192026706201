.widget {
  background-color: green;
  position: absolute;
  color: white;
  text-align: center;
}

.simple-widget {
  border-radius : 4px; 
  color: white;
  text-align: center;
}
  
.simple-widget-title {
  background-color: #424242;
  border-radius : 4px 4px 0px 0px;
  padding : 5px;
  /*min-height: 30px;*/
}

.simple-widget-content {
  background-color: green;
  padding: 5px;
  /*min-height: 60px;*/
  font-size: 30px;
}


.chart-widget{
  background-color: transparent;
}